<template>
  <div>
    <div class="title">
      {{ $t('serviceProvider.settingsProduct.settingsProducts') }}
    </div>

    <Header
      :sorting-options="tableSorting.options"
      :search-label="$t('serviceProvider.settingsProduct.searchProducts')"
      @sort="option => (tableSorting.selected = option)"
      @search="inp => (search = inp)"
    >
      <template #button-1>
        <template v-if="!selected">
          <Button :orange="true" @click="proceedToCreate">
            <PlusIcon width="22px" height="22px" />
            {{ $t('serviceProvider.settingsProduct.createProduct') }}
          </Button>
        </template>
        <template v-else>
          <Button :orange="true" @click="productEdit">
            {{ $t('serviceProvider.settingsProduct.edit') }}
          </Button>
        </template>
      </template>

      <template #button-2>
        <template v-if="!selected">
          <Button :orange-secondary="true" @click="modalActivate('category')">
            <PlusIcon width="22px" height="22px" color="#e58413" />
            {{ $t('serviceProvider.settingsProduct.createCategory') }}
          </Button>
        </template>
        <template v-else>
          <Button :orange-secondary="true" @click="productDelete">
            {{ $t('serviceProvider.settingsProduct.delete') }}
          </Button>
        </template>
      </template>
    </Header>

    <Table
      style="margin-bottom: 1.5rem"
      :data="products"
      :keys="tableKeys"
      :column-width="tableColumnWidth"
      :selectable="true"
      @itemSelect="selectItem"
    >
      <template #cell-images="{ value }">
        <div class="image-wrapper">
          <img
            :src="
              value.images
                ? parseJsonOfImages(value.images)[0]
                : require('@/assets/pics/logo_black.png')
            "
            class="image"
          />
        </div>
      </template>
      <template #cell-category="{ cell }">
        <div>
          {{ cell ? cell.name : '' }}
        </div>
      </template>
      <template #cell-duration="{ value }">
        <span>{{ value.duration }} Minuten</span>
      </template>
      <template #cell-price="{ value }">
        <span>{{ value.price | EUR }}</span>
      </template>
      <template #cell-delivery="{ value }">
        <span>{{ getDeliveryColumn(value.delivery) }} </span>
      </template>
    </Table>

    <!-- create category modal -->
    <Modal :open="modalActive">
      <component :is="active" @close="toggleModal"> </component>
    </Modal>
  </div>
</template>

<script>
import { filter } from 'lodash';
import store from '@/store';
import Table from '@/components/Staff/TableDivCustom';
import Header from '@/components/Staff/TableHeaderCustom';
import Modal from '@/components/Modal/Modal';
import ModalProductsCategory from '@/components/Products/ModalProductsCategory';
import CheckboxElement from '@/components/FormControl/CheckboxElement';
import Button from '@/components/FormControl/ButtonElement';
import PlusIcon from '@/components/Svgs/PlusIcon';
import checkIsAndParseJson from '../../../utils/checkIsAndParseJson';

export default {
  name: 'SettingsProducts',

  components: {
    Table,
    Header,
    Modal,
    CheckboxElement,
    Button,
    PlusIcon,
  },

  data() {
    return {
      selectionActive: false,
      modalActive: false,
      active: false,
      selected: false,
      editActive: false,
      search: '',
      modalComponents: {
        category: ModalProductsCategory,
      },
      tableSorting: {
        selected: false,
        options: [
          {
            label: this.$t('serviceProvider.settingsServices.category'),
            value: 'category',
            key: 'categoryId',
          },
          // {
          //   label: this.$t('serviceProvider.settingsServices.duration'),
          //   value: 'duration',
          // },
          {
            label: this.$t('serviceProvider.settingsServices.price'),
            value: 'price',
            key: 'price',
          },
        ],
      },
      tableKeys: [
        {
          key: 'images',
          label: 'serviceProvider.settingsProduct.picture',
          width: '50px',
        },
        {
          key: 'name',
          label: 'serviceProvider.settingsProduct.name',
          width: '100px',
        },
        {
          key: 'description',
          label: 'serviceProvider.settingsProduct.description',
          width: '100px',
        },
        {
          key: 'quantity',
          label: 'serviceProvider.settingsProduct.quantity',
          width: '150px',
        },
        {
          key: 'delivery',
          label: 'serviceProvider.settingsProduct.delivery',
          width: '150px',
        },
        {
          key: 'price',
          label: 'serviceProvider.settingsProduct.price',
          width: '150px',
        },
      ],
      tableColumnWidth: [
        { key: 'images', width: '80px', fixed: true },
        { key: 'name', width: '200px', fixed: false },
        { key: 'description', width: '150px', fixed: false },
        { key: 'category', width: '150px', fixed: false },
        { key: 'duration', width: '150px', fixed: false },
        { key: 'price', width: '150px', fixed: false },
      ],
    };
  },
  created() {
    store.dispatch('fetchProducts');
  },
  methods: {
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    modalActivate(type) {
      this.active = this.modalComponents[type];
      this.toggleModal();
    },
    selectItem(item) {
      this.selected = this.selected === item ? false : item;
    },
    productDelete() {
      this.$store.dispatch('deleteProduct', this.selected.id);
    },
    productEdit() {
      this.proceedToCreate();
    },
    proceedToCreate() {
      if (this.selected) {
        this.$router.push({
          name: 'SettingsProductsCreate',
          params: { product: this.selected },
        });
      } else {
        this.$router.push({ name: 'SettingsProductsCreate' });
      }
    },
    parseJsonOfImages(img) {
      return checkIsAndParseJson(img);
    },
    getDeliveryColumn(delivery) {
      const enabled = JSON.parse(delivery)?.delivery?.enabled;
      const days = JSON.parse(delivery)?.delivery?.delivery_days;
      return enabled && days ? days + ' days' : 'N/A';
    },
  },
  computed: {
    products() {
      let sortedData;
      const data = store.getters.products.slice();
      const products = this.search
        ? filter(
            data,
            item =>
              item.name.includes(this.search) ||
              item.category_id.includes(this.search)
          )
        : data;
      if (this.tableSorting.selected) {
        const sortedKey = filter(
          this.tableSorting.options,
          option => option.value === this.tableSorting.selected
        )[0].key;
        sortedData = products.sort((a, b) => +a[sortedKey] - +b[sortedKey]);
      } else {
        sortedData = products;
      }
      return sortedData;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  height: 48px;
  overflow: hidden;
  width: 48px;
  .image {
    width: 100%;
  }
}
</style>
