var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('retailer.settingsProducts.settingsProducts'))+" ")]),_c('header',[_c('ButtonElement',{attrs:{"orange":""},on:{"click":function($event){return _vm.$store.commit('setShowAppointmentCreateModal', true)}}},[_c('PlusIcon',{attrs:{"width":"22px","height":"22px"}}),_vm._v(" "+_vm._s(_vm.$t('retailer.settingsProducts.createProduct'))+" ")],1),_c('form',{staticClass:"search"}),_c('SelectElement',{staticClass:"sort",staticStyle:{"width":"160px"},attrs:{"placeholder":_vm.$t('retailer.settingsProducts.sortBy'),"background-color":"grey","options":_vm.sortOptions},on:{"dropdown-closed":function($event){return _vm.handleDataSort(_vm.selectedSort)}},model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}})],1),(_vm.$store.getters.appointmentsToTableSimple)?_c('TableDiv',{staticClass:"products-table",attrs:{"data":_vm.$store.getters.appointmentsToTableSimple},scopedSlots:_vm._u([{key:"header-date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('retailer.settingsProducts.date'))+" ")]}},{key:"header-time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('retailer.settingsProducts.startTime'))+" - "+_vm._s(_vm.$t('retailer.settingsProducts.endTime'))+" ")]}},{key:"header-quantity",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t('retailer.settingsProducts.quantity'))+" ")]}},{key:"cell-date",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"txt-orange"},[_vm._v(_vm._s(_vm.formatDate(value, 'dd/MM/yyyy')))])]}},{key:"cell-time",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm.getTimeSlot(value.start, value.end)))])]}},{key:"cell-quantity",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"txt-orange"},[_vm._v(_vm._s(value))])]}}],null,false,2953776052)}):_vm._e(),_c('Modal',{attrs:{"open":_vm.$store.getters.showAppointmentCreateModal,"custom-style":{ width: '450px' }},on:{"backdropClick":function($event){return _vm.$store.commit('setShowAppointmentCreateModal', false)}}},[_c('NewSimpleAppointmentModal',{on:{"cancel":function($event){return _vm.$store.commit('setShowAppointmentCreateModal', false)},"approve":_vm.handleAppointmentCreation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }