<template>
  <div class="modal-products-category">
    <!-- Header -->
    <div class="header">
      {{ $t('serviceProvider.settingsServices.createCategory') }}
      <div
        style="
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          z-index: 1000;
        "
        @click="$emit('close')"
      >
        <XIcon />
      </div>
    </div>

    <!-- Content -->
    <div class="content">
      <div class="input-wrap">
        <label class="label">
          {{ $t('serviceProvider.settingsServices.subcategory') }}
        </label>
        <SelectElement
          v-model="formData.subcategory_id"
          :options="subcategoryOptions"
          search
          validation-rules="required"
          :placeholder="$t('serviceProvider.settingsServices.subcategory')"
        />
      </div>

      <div class="input-wrap">
        <label>
          {{ $t('serviceProvider.settingsServices.cateogoryName') }}
        </label>
        <InputElement
          v-model="formData.name"
          validation-rules="required"
          validation-name="text"
          :placeholder="$t('serviceProvider.settingsServices.cateogoryName')"
        />
      </div>

      <div class="input-wrap">
        <label>Bestehende Kategorien:</label>
        <div style="max-height: 100px; overflow-y: auto">
          <li v-for="cat in customProductCategories" :key="cat.id">
            {{ cat.name }}
          </li>
        </div>
      </div>
    </div>

    <!-- ResponseAlert -->
    <ResponseAlert
      v-if="$store.getters.errorResponse.action == 'createProductCategory'"
      :response="$store.getters.errorResponse.data"
    ></ResponseAlert>

    <!-- Footer -->
    <div class="actions">
      <ButtonElement :orange-secondary="true" @click="$emit('close')">
        {{ $t('serviceProvider.settingsServices.cancel') }}
      </ButtonElement>
      <ButtonElement
        :orange="true"
        @click="save"
        :loading="$store.getters.loading"
      >
        {{ $t('serviceProvider.settingsServices.save') }}
      </ButtonElement>
    </div>

    <SuccessNotification>
      Deine Kategorie wurde erfolgreich erstellt!
    </SuccessNotification>
  </div>
</template>

<script>
import SuccessNotification from '@/components/Staff/SuccessNotification';

export default {
  components: {
    SuccessNotification,
  },

  data() {
    return {
      formData: {
        name: '',
        subcategory_id: '',
      },
    };
  },
  computed: {
    subcategoryOptions() {
      return this.$store.getters.companySubcategoriesOnly.map(item => ({
        label: item.name,
        value: item.id,
      }));
    },
    customProductCategories() {
      return this.$store.getters.customProductCategories || [];
    },
  },
  created() {
    this.$store.commit('setErrorResponse', {});
    this.$store.dispatch('fetchCompanyCategories');
    this.$store.dispatch('fetchCustomProductCategories');
  },
  methods: {
    save() {
      this.$store
        .dispatch('createProductCategory', {
          name: this.formData.name,
          subcategory_id: this.formData.subcategory_id,
        })
        .then(() => {
          this.$emit('close');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-products-category {
  max-width: 500px;
  background: #fdfdfd;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px 40px 30px 40px;
}
.header {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 700 !important;
  color: #e58413;
  margin-bottom: 25px;
}
.input-wrap {
  margin-bottom: 24px;

  label {
    display: inline-block;
    font-weight: 500;
    color: #e58413;
    padding-bottom: 7px;
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    width: 48%;
    justify-content: center;
  }
}
</style>
