<template>
  <div class="modal-custom">
    <div class="close">
      <ButtonElement @click="$emit('cancel')" link grey>
        <XIcon />
      </ButtonElement>
    </div>
    <div class="header">
      {{ $t('serviceProvider.calendar.newAppointment') }}
    </div>
    <div class="content">
      <div class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.date') }}
          </label>
          <DateRangePicker
            v-model="createAppointmentModel.date"
            :ranges="false"
            single-date-picker
            auto-apply
            class="date-picker"
            :min-date="new Date(new Date().setHours(0, 0, 0, 0))"
            :locale-data="{ firstDay: 1 }"
          >
            <template #input="{ startDate }">
              {{ dateFormat(startDate) }}
            </template>
          </DateRangePicker>
        </span>
      </div>
      <div class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.timeStart') }}
          </label>
          <TimePicker
            v-model="createAppointmentModel.time_start"
            :minute-interval="5"
            :hour-range="timeStartRange.hour"
            :minute-range="timeStartRange.minute"
            hide-disabled-items
            hide-clear-button
            close-on-complete
            auto-scroll
            class="time-picker"
            placeholder="Uhrzeit wählen"
          />
        </span>
        <span class="right">
          <label class="label">
            {{ $t('serviceProvider.calendar.timeEnd') }}
          </label>
          <TimePicker
            v-model="createAppointmentModel.time_end"
            :minute-interval="5"
            :hour-range="timeEndRange.hour"
            :minute-range="timeEndRange.minute"
            hide-disabled-items
            hide-clear-button
            close-on-complete
            auto-scroll
            class="time-picker"
            placeholder="Uhrzeit wählen"
          />
        </span>
      </div>
      <div class="content-row">
        <span class="left">
          <label class="label">
            {{ $t('serviceProvider.calendar.capacity') }}
          </label>
          <InputElement v-model="createAppointmentModel.limit" />
        </span>
      </div>
    </div>

    <div>
      <ResponseAlert
        v-if="$store.getters.errorResponse.action == 'appointmentEdit'"
        :response="$store.getters.errorResponse.data"
        style="margin-bottom: 1rem"
      ></ResponseAlert>
    </div>

    <div class="footer">
      <ButtonElement @click="$emit('cancel')" orange-secondary>
        {{ $t('serviceProvider.calendar.cancel') }}
      </ButtonElement>
      <ButtonElement
        @click="$emit('approve', createAppointmentModel)"
        :loading="$store.getters.loading"
        :[colorApproveButton]="true"
        orange
      >
        {{ $t('serviceProvider.calendar.save') }}
      </ButtonElement>
    </div>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import TimePicker from 'vue2-timepicker/src/vue-timepicker.vue';
import ButtonElement from '../../FormControl/ButtonElement.vue';
import InputElement from '../../FormControl/InputElement.vue';
import { intlShortDateFormat } from '../../../config/intl';
import moment from 'moment';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  setup() {
    const createAppointmentModel = reactive({
      date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      time_start: '',
      time_end: '',
      limit: '',
    });

    const dateFormat = date => {
      return date ? intlShortDateFormat.format(date) : '';
    };

    return {
      createAppointmentModel,
      dateFormat,
    };
  },
  computed: {
    timeStartRange() {
      const isToday = this.isToday(this.createAppointmentModel.date.startDate);
      const startHour = this.createAppointmentModel.time_start.split(':')[0];

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (isToday && i < moment().format('HH')) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (
          isToday &&
          startHour == moment().format('HH') &&
          i < moment().format('mm')
        ) {
          continue;
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },

    timeEndRange() {
      if (!this.createAppointmentModel.time_start) {
        return {
          hour: [],
          minute: [],
        };
      }

      const timeStart = this.createAppointmentModel.time_start.split(':');
      const timeEnd = this.createAppointmentModel.time_end.split(':');

      if (timeStart[0] == 'HH' || timeStart[1] == 'mm') {
        return {
          hour: [],
          minute: [],
        };
      }

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (i < timeStart[0]) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (timeEnd[0] == timeStart[0] && i <= timeStart[1]) {
          continue;
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },
  },
  created() {
    this.$store.commit('setErrorResponse', {});
  },
  methods: {
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
  },
  components: {
    DateRangePicker,
    InputElement,
    ButtonElement,
    TimePicker,
    ResponseAlert,
  },
  props: {
    colorApproveButton: {
      type: String,
      default: '',
    },
    textApproveButton: {
      type: String,
      default: 'Approve',
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-custom {
  max-width: 450px;
  position: relative;
  padding: 2rem;
}
.close {
  position: absolute;
  top: 10px;
  right: 20px;

  ::v-deep path {
    stroke: $hty-black;
  }
}
.date-picker {
  width: 100%;
  color: $hty-dark-grey;

  ::v-deep .reportrange-text {
    border: $hty-border;
    height: 44px;
    border-radius: $hty-radius;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
  }
}
.time-picker {
  width: 100%;

  ::v-deep .display-time {
    color: $hty-dark-grey;
    width: 100%;
    border: $hty-border;
    height: 44px;
    border-radius: $hty-radius;
  }
}
.content-row {
  display: flex;
  margin-bottom: 1.5rem;

  > * {
    width: 100%;

    .label {
      display: flex;
      justify-content: flex-start;
    }
  }

  > .right {
    margin-left: 1.5rem;
  }
}
.header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.2;
  color: $hty-orange;
  text-align: center;
}
.content {
  width: 100%;
  margin: 1.5rem auto 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-align: center;
}
.footer {
  display: flex;
  justify-content: center;

  > * {
    display: inline;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
    letter-spacing: 0.035rem;
  }

  > :last-child {
    margin-left: 20px;
  }
}
@media (max-width: $hty-sm) {
  .footer {
    justify-content: center;
  }
}
</style>
