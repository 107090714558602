<template>
  <div class="products">
    <div class="title">
      {{ $t('retailer.settingsProducts.settingsProducts') }}
    </div>

    <header>
      <ButtonElement
        orange
        @click="$store.commit('setShowAppointmentCreateModal', true)"
      >
        <PlusIcon width="22px" height="22px" />
        {{ $t('retailer.settingsProducts.createProduct') }}
      </ButtonElement>

      <form class="search">
        <!-- <SearchIcon />
        <InputElement
          class="search-input"
          :placeholder="$t('retailer.settingsProducts.searchProducts')"
          v-model="searchString"
        /> -->
      </form>

      <SelectElement
        class="sort"
        style="width: 160px"
        :placeholder="$t('retailer.settingsProducts.sortBy')"
        background-color="grey"
        :options="sortOptions"
        v-model="selectedSort"
        @dropdown-closed="handleDataSort(selectedSort)"
      />
    </header>

    <TableDiv
      v-if="$store.getters.appointmentsToTableSimple"
      class="products-table"
      :data="$store.getters.appointmentsToTableSimple"
    >
      <template #header-date="{ value }">
        {{ $t('retailer.settingsProducts.date') }}
      </template>
      <template #header-time="{ value }">
        {{ $t('retailer.settingsProducts.startTime') }} -
        {{ $t('retailer.settingsProducts.endTime') }}
      </template>
      <template #header-quantity="{ value }">
        {{ $t('retailer.settingsProducts.quantity') }}
      </template>

      <template #cell-date="{ value }">
        <span class="txt-orange">{{ formatDate(value, 'dd/MM/yyyy') }}</span>
      </template>
      <template #cell-time="{ value }">
        <span>{{ getTimeSlot(value.start, value.end) }}</span>
      </template>
      <template #cell-quantity="{ value }">
        <span class="txt-orange">{{ value }}</span>
      </template>
    </TableDiv>

    <Modal
      :open="$store.getters.showAppointmentCreateModal"
      @backdropClick="$store.commit('setShowAppointmentCreateModal', false)"
      :custom-style="{ width: '450px' }"
    >
      <NewSimpleAppointmentModal
        @cancel="$store.commit('setShowAppointmentCreateModal', false)"
        @approve="handleAppointmentCreation"
      />
    </Modal>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import ButtonElement from '../../../components/FormControl/ButtonElement.vue';
import PlusIcon from '../../../components/Svgs/PlusIcon';
import SearchIcon from '../../../components/Svgs/SearchIcon';
import InputElement from '../../../components/FormControl/InputElement';
import SelectElement from '../../../components/FormControl/SelectElement/SelectElement';
import TableDiv from '../../../components/Table/TableDiv';
import store from '../../../store';
import Modal from '../../../components/Modal/Modal';
import NewSimpleAppointmentModal from '../../../components/Modal/Appointment/NewSimpleAppointmentModal';
import {
  dateFormat as formatDate,
  getTimeSlot,
} from '../../../utils/dateTimeHelpers';

export default {
  setup() {
    const searchString = ref('');
    const selectedSort = ref('');

    const dateFormat = date => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const handleAppointmentCreation = async data => {
      await store.dispatch('saveAppointment', {
        date_start: dateFormat(data.date.startDate),
        time_start: data.time_start,
        time_end: data.time_end,
        limit: data.limit,
      });
      store.commit('setShowSuccessfulActionModal', false);
      store.dispatch('fetchAppointments');
    };

    const handleDataSort = type => {
      console.log(type);
      let sortBy = 'time';
      let sort = 'asc';

      if (type == 'time_start') {
        sortBy = 'time';
        sort = 'asc';
      } else if (type == 'time_end') {
        sortBy = 'time';
        sort = 'desc';
      } else if (type == 'quantity') {
        sortBy = 'quantity';
        sort = 'desc';
      }

      store.dispatch('fetchAppointments', {
        sortBy,
        sort,
      });
    };

    onMounted(() => {
      store.dispatch('fetchAppointments');
      store.dispatch('fetchCompanyLocations');
    });

    return {
      searchString,
      selectedSort,
      handleAppointmentCreation,
      handleDataSort,
      formatDate,
      getTimeSlot,
    };
  },
  computed: {
    sortOptions() {
      return [
        {
          label: this.$t('retailer.settingsProducts.startTime'),
          value: 'time_start',
        },
        {
          label: this.$t('retailer.settingsProducts.endTime'),
          value: 'time_end',
        },
        {
          label: this.$t('retailer.settingsProducts.quantity'),
          value: 'quantity',
        },
      ];
    },
  },
  components: {
    InputElement,
    SearchIcon,
    ButtonElement,
    PlusIcon,
    SelectElement,
    TableDiv,
    Modal,
    NewSimpleAppointmentModal,
  },
};
</script>

<style lang="scss">
.products {
  header {
    display: flex;

    .sort {
      margin-left: 1.5rem;
    }
  }

  .search {
    position: relative;
    margin-left: auto;

    svg {
      position: absolute;
      top: 0.8rem;
      left: 0.8rem;
    }

    input {
      padding-left: 3rem;
    }
  }

  .products-table {
    margin-top: 1.5rem;

    .img-container {
      width: 3rem;
      height: 3rem;
      border-radius: 0.625rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .txt-orange {
      color: $hty-orange;
    }

    .row.item {
      height: 76px;
    }
  }
}
</style>
